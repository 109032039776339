<template>
  <header class="px-1 container mx-auto max-w-8xl">
    <OnBoarding v-if="auth.authenticated.value"/>
    <div
        class="flex justify-center items-center h-[45px] sm:h-[56px] sm:pt-1"
    >
      <ButtonAdd class="z-40"/>
    </div>
    <div class="flex gap-1 mt-[-45px] sm:mt-[-56px]">
      <NuxtLink to="/" id="logowithtext">
        <slot name="logowithtext"></slot>
      </NuxtLink>
      <div class="grow"></div>
      <div class="hidden lg:flex items-center gap-1 justify-end">
        <NuxtLink class="navigation" to="/spots">{{ $t("navigation.spots") }}</NuxtLink>
        <NuxtLink class="navigation" to="/map">{{ $t("navigation.map") }}</NuxtLink>
      </div>
      <div v-if="auth.authenticated.value" class="flex items-center gap-1">
        <NuxtLink v-if="auth.user.value?.premium" class="navigation hidden lg:flex" to="/premium">{{
            $t("navigation.premium")
          }}
        </NuxtLink>
        <NuxtLink class="navigation shrink-0" to="/profile">
          <UserImage size="md" :imageId="auth.user.value?.profileImageId" :alt="$t('navigation.profile')"/>
        </NuxtLink>
        <NotificationDrawer v-if="auth.authenticated.value"/>
      </div>
      <div class="flex items-center ml-1">
        <SideMenu/>
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
const auth = useAuth();

function openOnboardingModal() {
  (document.getElementById("onboarding_modal") as HTMLFormElement).showModal();
  auth.setShowOnboarding(false);
}

onMounted(() => {
  if (auth.showOnboarding.value) {
    openOnboardingModal();
  }
});

watch(() => auth.showOnboarding.value, (value) => {
  if (value) {
    openOnboardingModal();
  }
});

</script>
<style lang="postcss" scoped>

.navigation.router-link-active {
  @apply underline;
}

.navigation {
  @apply text-theme-secondary;
}

.navigation:hover {
  @apply text-theme-primary;
}

.navigation img {
  @apply hover:border-theme-primary active:border-theme-primary;
}

</style>
