<template>
  <Drawer id="sidemenu" ref="sidemenu">
    <template #button>
      <IconBurger/>
    </template>
    <template #default
    >
      <div class="bg-theme-primary w-[350px] h-screen overflow-y-auto">
        <div
            class="bg-theme-primary text-theme-font p-2 m-0 min-h-screen flex flex-col gap-1"
        >
          <div class="flex-none flex mt-0.5 items-center">
            <IconLogo class="h-[45px]" :dark="true"/>
            <div class="flex-none flex items-center px-1">
              <h1 class="font-semibold text-[1.3125rem] visible">{{ platformName }}</h1>
            </div>
          </div>
          <ul class="flex flex-none flex-col gap-1">
            <li>
              <NuxtLink @click="closeMenu()" to="/"
              ><h2>{{ $t("navigation.home") }}</h2></NuxtLink
              >
            </li>
            <li>
              <NuxtLink @click="closeMenu()" to="/spots"
              ><h2>{{ $t("navigation.spots") }}</h2></NuxtLink
              >
            </li>
            <li>
              <NuxtLink @click="closeMenu()" to="/map">
                <h2>{{ $t("navigation.map") }}</h2>
              </NuxtLink>
            </li>
            <li v-if="auth.user.value?.premium">
              <NuxtLink @click="closeMenu()" to="/premium">
                <h2>{{ $t("navigation.premium") }}</h2>
              </NuxtLink>
            </li>
            <li v-if="auth.authenticated.value">
              <NuxtLink @click="closeMenu()" to="/profile"
              ><h2>{{ $t("navigation.profile") }}</h2></NuxtLink
              >
            </li>
            <li v-if="auth.authenticated.value">
              <NuxtLink @click="closeMenu()" to="/settings"
              ><h2>{{ $t('navigation.settings') }}</h2></NuxtLink
              >
            </li>
            <li v-if="!auth.authenticated.value">
              <button @click="login()">
                <h2>{{ $t("navigation.login") }}</h2>
              </button>
            </li>
            <li v-if="auth.authenticated.value">
              <button @click="logout()">
                <h2>{{ $t("navigation.logout") }}</h2>
              </button>
            </li>
          </ul>
          <ul class="flex flex-none flex-col">
            <li v-if="auth.authenticated.value">
              <NuxtLink @click="openOnboardingModal()"
              ><p>Onboarding</p></NuxtLink
              >
            </li>
            <li>
              <NuxtLink @click="closeMenu()" to="/about"
              ><p>{{ $t("navigation.about") }}</p></NuxtLink
              >
            </li>
            <li>
              <NuxtLink @click="closeMenu()" to="/partner"
              ><p>{{ $t("navigation.partner") }}</p></NuxtLink
              >
            </li>
            <li>
              <NuxtLink @click="closeMenu()" to="/news"
              ><p>{{ $t("navigation.news") }}</p></NuxtLink
              >
            </li>
          </ul>
          <div class="grow"></div>
          <div class="flex flex-none gap-2 mt-1">
            <LanguageSwitcher/>
          </div>
        </div>
      </div>
    </template
    >
  </Drawer>
</template>
<script setup lang="ts">

const platformSettings = usePlatformSettings();
const platformName = platformSettings.platformName;
const i18n = useI18n();

const auth = useAuth();
const sidemenu = ref();


function openOnboardingModal() {
  (document.getElementById("onboarding_modal") as HTMLFormElement).showModal();
  sidemenu.value.closeDrawer();
}

function login() {
  auth.login();
}

function logout() {
  auth.logout({redirectUri: window.location.origin});
}

function closeMenu() {
  sidemenu.value.closeDrawer();
}
</script>
<style lang="postcss" scoped>
.router-link-active h2 {
  @apply underline underline-offset-8 decoration-4;
}

h2 {
  @apply text-4xl leading-6;
}

a p {
  @apply leading-4;
}

a:hover,
button:hover {
  @apply bg-transparent text-theme-secondary;
}
</style>
