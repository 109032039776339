<template>
  <Drawer ref="drawer" id="notifications" @open="loadNotifications()">
    <template #button
    >
      <div class="relative">
        <IconBell
            class="h-[30px] w-[30px] fill-theme-secondary hover:fill-theme-primary"
            :alt="t('accessibility.notifications')"
            :active="true"
        />
        <div v-if="numberOfUnreadNotificationsForHeader != 0"
             class="absolute top-[3px] right-[6px] text-white bg-theme-primary h-[16px] w-[16px] rounded-[8px] flex items-center justify-center translate-x-1/2">
          <p class="text-white text-[10px]">{{ numberOfUnreadNotificationsForHeader }}</p>
        </div>
      </div>
    </template>
    <template #default>
      <div class="flex flex-col h-screen text-theme-font bg-white min-w-[384px] max-w-sm lg:max-w-md">
        <div class="relative">
          <div class="absolute top-0 left-0 right-0 h-[105px] bg-white p-2">
            <div class="h-[60px] flex justify-start items-center w-full gap-2 px-1">
              <h1 class="text-theme-secondary font-semibold text-[1.3125rem] visible">
                {{ $t("notifications.notifications_title") }}
              </h1>
            </div>
            <div class="flex gap-1 justify-end p-0">
              <button :disabled="!userHasUnreadNotifications"
                      class="text-theme-primary disabled:text-theme-font-console" @click="markAllNotificationsRead">
                {{ $t('notifications.mark_all_as_read') }}
              </button>
            </div>
          </div>
        </div>
        <div id="scroll-box" class="overflow-y-auto grow mt-[110px] px-2">
          <div class="gap-1 mt-1 divide-y divide-theme-secondary/30">
            <div v-if="!loading && notifications && notifications?.length == 0" class="px-1 my-2 text-xl">
              {{ $t("notifications.notifications_no_notifications") }}
            </div>
            <NotificationListElement :notifications="notifications" @close-drawer="closeDrawer()"/>
          </div>
          <div class="flex justify-center my-2">
            <div v-if="loading" class="flex justify-center items-center">
              <LoadingSpinner/>
            </div>
            <div
                v-if="error"
                class="flex flex-col justify-center items-center gap-1 my-1"
            >
              <p>{{ $t("error.generic") }}</p>
              <ButtonPrimary @click="loadNotifications">{{ $t('error.retry') }}</ButtonPrimary>
            </div>
            <ButtonPrimary v-if="!loading && !lastPage && notifications.length != 0" @click="loadNotifications">{{
                $t("notifications.load_more")
              }}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </template>
  </Drawer>
</template>
<script setup lang="ts">

const notifications = ref<Array<Notification>>([]);
const {t} = useI18n();
const auth = useAuth();
const loading = ref(false);
const error = ref(false);
const size = ref(7);
const page = ref(0);
const lastPage = ref(true);
const numberOfUnreadNotificationsForHeader = ref(0);
const userHasUnreadNotifications = computed(() => {
  return notifications.value.some((notification) => !notification.read);
});
const drawer = ref();

useAsyncData(() => getNumberOfUnreadNotifications());

function closeDrawer() {
  drawer.value.closeDrawer();
}

async function getNumberOfUnreadNotifications() {
  return movAuthRequest.get<number>("users/current/notifications/unreads").then((result) => {
    numberOfUnreadNotificationsForHeader.value = result;
  });
}

function markAllNotificationsRead() {
  movAuthRequest.get("users/current/notifications/read").then(() => {
    notifications.value.forEach((notification) => {
      notification.read = true;
    });
    numberOfUnreadNotificationsForHeader.value = 0;
  });
}

function loadNotifications() {
  if (!auth.authenticated.value) {
    return;
  }
  loading.value = true;
  error.value = false;

  movAuthRequest
      .get<NotificationsResponse>("users/current/notifications", {
        size: size.value,
        page: page.value
      })
      .then((result) => {
        notifications.value.push(...result.notificationContents);
        lastPage.value = result.lastPage;
        page.value++;
      })
      .catch((theError) => {
        error.value = true;
      }).finally(() => {
    loading.value = false;
  });
}
</script>
<style lang="postcss" scoped>

#scroll-box {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  background: /* Shadow Cover TOP */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
  ) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(
      rgba(255, 255, 255, 0),
      white 70%
  ) center bottom,
    /* Shadow TOP */ radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
  ) center top,
    /* Shadow BOTTOM */ radial-gradient(
      farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
  ) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;

}
</style>
