<template>
  <div class="drawer drawer-end" :id="id">
    <input
        :id="id + '-drawer'"
        type="checkbox"
        class="drawer-toggle visible"
        :class="id + '-drawer-toggle'"
    />
    <div class="drawer-content">
      <!-- Page content here -->
      <label
          :for="id + '-drawer'"
          class="cursor-pointer"
          @click="setOpen(true)"
      >
        <slot name="button"></slot>
      </label>
    </div>
    <div class="drawer-side z-50">
      <label
          :for="id + '-drawer'"
          aria-label="close sidebar"
          class="drawer-overlay"
      ></label>
      <div class="menu w-80 min-h-full text-black p-0">
          <button @click="closeDrawer()" class="absolute right-2 top-2 z-50">
              <img src="~/assets/icon/close.svg" class="p-2" :alt="$t('accessibility.close')" />
          </button>
        <!-- Sidebar content here -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{ id: string }>()

defineExpose({
  closeDrawer
});

const emit = defineEmits(["open"]);
const open = ref(false);

function closeDrawer() {
  const elements = document.getElementsByClassName(
      props.id + "-drawer-toggle"
  ) as HTMLCollectionOf<HTMLInputElement>;

  if (elements.length > 0) {
    const drawer = elements[0];
    drawer.checked = false;
  } else if (elements.length > 1) {
    console.log("WARNING: more than one drawer-toggle element found!");
  }

  setOpen(false);
}

function setOpen(value: boolean) {
  if (value) {
    emit("open");
  }
  open.value = value;
}
</script>
<style lang="postcss" scoped>

.drawer-side {
  overflow: hidden;
}

</style>
